import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HashTags from "../components/hashtags"
import NavLink from "../components/navlink"

let imgWidth = 140

const Partners = props => (
  <StaticQuery
    query={graphql`
    query {
      allPartnersJson {
        edges {
          node {
            titolo
            testo
            url
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 60
                )
              }
            }
          }
        }
      }
    }`}
    render={data => (
      <>
      <Layout location="/partners" title="Partners">
        <Seo title="Partners" />
        <h1 className="text-center text-4xl mb-4">
          The Platinum Services | Dmc Italy{" "}
        </h1>
        <h2 className="text-center text-2xl mb-4">
          Hotel Partners | Italy | Europe
        </h2>

        <p className="text-xl mb-4 text-center">
          The staff of The Platinum Services Dmc Italy and Events, throughout the
          years, has carefully selected and personally visited a number of hotels,
          including luxury, boutique, and resort properties.
          <br />
          Every year, we go through them again to make sure you're comfortable and
          safe.
        </p>
            {getPartners(data.allPartnersJson.edges)}
        </Layout>
      </>
    )}
  />
)

function getPartners(partners) {
  let objRow = []

  Object.keys(partners).forEach(function (val, index) {
    let partner = partners[val].node
    objRow.push(getCol(partner, index))
  })

  return <div className="flex flex-wrap -mx-2 p-4">{objRow}</div>
}
function getCol(partner, index) {

  return (
    <div key={"partner_" + index.toString()} className="w-full lg:w-1/2 px-2 mb-4">
      <div className="bg-white shadow-md rounded-md xl:my-2 xl:p-1 w-full h-full flex flex-col">
        <div className="p-3 flex flex-wrap items-stretch flex-grow">
          <div className="w-1/3 mr-4 mb-3 float-left">
            <GatsbyImage
              className="w-full h-auto object-cover rounded-xl"
              loading="eager"
              image={getImage(partner.image)}
              alt={partner.titolo}
            />
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <div className="font-bold text-lg mb-1">{partner.titolo}</div>
            <p className="text-gray-700 text-sm">{partner.testo}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners